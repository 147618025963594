<template>
	<div class="cs-block cs-blog-post-archive" :class="`${styleClass}${hasBg}`">
		<div class="cs-block-base">
			<div class="container">
				<CSBlogPostArchive :data="data" />
			</div>
		</div>
	</div>
</template>

<script>
	import {computed} from '../libs/common-fn';

export default {
		name: "BlogPostArchive",
		components: {
			CSBlogPostArchive: () => import("../components/CSBlogPostArchive.vue"),
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {}
		},
		computed: {
			...computed('BlogPostArchive'),
			hasBg() {
				if (this.data.style == 'color') return true;

				return false;
			}
		},
		mounted() {
			console.log(this)
		},
	}
</script>

<style lang="scss" scoped>
	@import "../styles/main.scss";
	.cs-blog-post-archive {
		&.cs-style- {
			&color {
				::v-deep {
					@include cs-block-color;
					.cs-title {
						color: white
					}
					.cs-sub-title {
						color: $sub_title_color;
					}

					.cs-text {
						color: $text_color_over_theme_color;

						::v-deep {
							* {
								color: $text_color_over_theme_color !important;
							}
						}
					}
				}
			}

			&light {
				::v-deep {
					@include cs-block-light-image;

					.cs-sub-title {
						color: $sub_title_color_dark;
					}
				}
			}

			&dark {
				::v-deep {
					@include cs-block-dark;
					.cs-sub-title {
						color: $sub_title_color_dark;
					}
				}
			}
		}
		::v-deep {
			.header-content {
				text-align: center;
				margin-bottom: $header_margin;
				&:last-child {
					margin-bottom: 0;
				}
				.cs-title, .cs-sub-title {
					margin-bottom: 20px;
				}
				.cs-text {
					::v-deep {
						* {
							text-align: center;
						}
						img {
							max-width: 100%;
							height: 300px;
							border-radius: 10px;
							margin: 10px;
						}
						p,
						span {
							@include text;
						}
						*:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			.cs-pc-tag {
				@include tag-btn;
			}
		}
	}
</style>
